<template>
  <div
    class="modal fade"
    id="resendNotificationModal"
    tabindex="-1"
    aria-labelledby="resendNotificationModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            @click="closeModal('cancel')"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          Deseja reenviar as notificações de <b>{{ proposalsType[proposalsSelected.proposal_status] }}</b> para <b>{{ proposalsSelected.name }}</b> ?
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-dismiss="modal"
            @click="closeModal('cancel')"
          >
            Fechar
          </button>
          <button
            type="button"
            class="btn btn-success"
            data-dismiss="modal"
            @click="closeModal('resend')"
          >
            Enviar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    proposalsSelected: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      proposalsType: {
        proposal_sent: "Proposta Enviada",
        account_created: "Conta Criada",
        payment_completed: "Pagto Concluido",
      },
    };
  },
  methods: {
    closeModal(event) {
      this.$emit(`close-modal-${event}`);
    },
  },
};
</script>
